import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"

const TagWithLink = ({ tag }) => {
  const { name, slug } = tag

  return (
    <Link
      to={`/training-news/tag/${slug}`}
      as="link"
      style="inline-block text-sm text-neutral-500 border border-neutral-500 rounded py-2 px-3 mr-5 mt-5 transition duration-300 ease-in-out hover:text-white hover:bg-amber-400 hover:border-amber-400 lg:mr-3.5"
    >
      {name}
    </Link>
  )
}

TagWithLink.propTypes = {
  tag: PropTypes.object,
}

export default TagWithLink
