import React from "react"
import Layout from "../components/layout"
import Consult from "../components/Consult"
import { useLandingPageData } from "../data/useLandingPageData"
import Breadcrumb from "../components/Breadcrumb"
import Sidebar from "../components/FindCoursePage/Sidebar"
import { graphql } from "gatsby"
import PostItem from "../components/Post/PostItem"
import PropTypes from "prop-types"
import PostCard from "../components/Card/PostCard"
import Pagination from "../components/Pagination"
import Subscribe from "../components/Post/Subscribe"
import TagWithLink from "../components/Tag/TagWithLink"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useMetadata } from "../data/use-metadata"

const TrainingNewsTemplate = ({ data, pageContext }) => {
  const { posts, popularPosts, recommendedPosts, tags } = data

  const { consultGatsbyImageData, consultTitle } = useLandingPageData()

  const slug = "training-news"

  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: "培訓新知",
      url: `/${slug}`,
    },
  ]

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/training-news`

  const postCards = posts.nodes.map(post => {
    return <PostCard key={post.id} data={post} />
  })

  const recommendedPostsItems = recommendedPosts.nodes.map(post => (
    <PostItem key={post.id} post={post} />
  ))

  const popularPostsItems = popularPosts.nodes.map(post => (
    <PostItem key={post.id} post={post} />
  ))

  const tagsItem = tags.nodes.map(tag => <TagWithLink key={tag.id} tag={tag} />)

  return (
    <Layout>
      <GatsbySeo
        title="培訓新知"
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: "培訓新知",
        }}
      />

      <div className="wrapper mt-12 mb-24 sm:mb-36">
        <Breadcrumb crumbs={crumbs} />
        <h1
          className="mt-12 mb-8 text-3xl !leading-normal tracking-wide font-bold text-neutral-800 sm:text-5xl"
          id="anchor_link"
        >
          培訓新知
        </h1>
        <div className="lg:grid lg:grid-cols-5 lg:gap-8">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-4 lg:h-fit">
            {postCards}
            <div className="sm:col-span-2">
              <Pagination slug={slug} pageContext={pageContext} />
            </div>
          </div>
          <div>
            <Sidebar
              popularItems={popularPostsItems}
              recommendedItems={recommendedPostsItems}
              isPostPage={true}
            />
            <div className="mt-16 border-t-4 border-amber-400 lg:mt-0">
              <p className="text-3xl leading-tight font-bold text-neutral-800 mt-3.5 mb-3 sm:mt-4">
                主題分類
              </p>
              {tagsItem}
            </div>
            <Subscribe />
          </div>
        </div>
      </div>
      <Consult
        gatsbyImageData={consultGatsbyImageData}
        consultTitle={consultTitle}
      />
    </Layout>
  )
}

export default TrainingNewsTemplate

TrainingNewsTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    posts: allContentfulPost(
      filter: { topic: { name: { eq: "培訓新知" } } }
      sort: { fields: updatedAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        slug
        name
        updatedAt(formatString: "YYYY-MM-DD")
        excerpt {
          excerpt
        }
        image {
          gatsbyImageData(
            width: 570
            height: 321
            placeholder: BLURRED
            quality: 100
          )
        }
        topic {
          name
          slug
        }
      }
    }
    recommendedPosts: allContentfulPost(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: {
        topic: { name: { eq: "培訓新知" } }
        isRecommended: { eq: true }
      }
    ) {
      nodes {
        id
        name
        updatedAt(formatString: "YYYY-MM-DD")
        slug
        topic {
          slug
        }
      }
    }
    popularPosts: allContentfulPost(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: { topic: { name: { eq: "培訓新知" } }, isPopular: { eq: true } }
    ) {
      nodes {
        id
        name
        updatedAt(formatString: "YYYY-MM-DD")
        slug
        topic {
          slug
        }
      }
    }
    tags: allContentfulTag {
      nodes {
        name
        slug
        id
      }
    }
  }
`
